<template>

  <div class="flex flex-col w-full">

    <div class="flex flex-col items-center">
      <div class="text-4xl text-pt-gray uppercase text-center font-bold mt-8 w-full">
        Our Works
      </div>

      <div class="flex gap-5 justify-between mt-8">
        <div class="w-4/12">
          <iframe
            height="200"
            src="https://www.youtube.com/embed/NI8fkchctSw?rel=0&amp;controls=0&amp;showinfo=0"
            frameborder="0"
            allowfullscreen="">
          </iframe>
        </div>
        <div class="w-4/12">
          <iframe
            height="200"
            src="https://www.youtube.com/embed/8-zOY_Mk-Ps?rel=0&amp;controls=0&amp;showinfo=0"
            frameborder="0"
            allowfullscreen="">
          </iframe>
        </div>
        <div class="w-4/12">
          <iframe
            height="200"
            src="https://www.youtube.com/embed/mHiI2kFMUAc?rel=0&amp;controls=0&amp;showinfo=0"
            frameborder="0"
            allowfullscreen="">
          </iframe>
        </div>
      </div>


      <div
        class="flex justify-center my-24"
        v-if="!bigImg"
      >
        <div
          class="flex flex-shrink justify-center items-center bg-pt-gray-text mr-2 rounded-l-lg text-white p-2 hover:bg-pt-gray cursor-pointer transition-all duration-200 ease-in-out"
          @click="pageChange('-')"
        >
          <i class="fas fa-chevron-left"></i>
        </div>

        <div class="flex flex-grow">
          <div class="grid grid-cols-5 gap-4">
            <div
              class="bg-pt-gray-text flex justify-center rounded-lg h-48 w-64 p-4"
              v-for="nr in 10"
              :key="nr"
            >
              <img
                v-if="(page*10 + nr) <= 49"
                class="object-cover cursor-pointer"
                :src="'https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/portfolio_' + (page == 1 ? nr : page*10 + nr) + '.jpg'"
                alt=""
                @click="setBigImg('https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/portfolio_' + (page == 1 ? nr : page*10 + nr) + '.jpg')"
              >
            </div>
          </div>
        </div>

        <div
          class="flex flex-shrink justify-center items-center bg-pt-gray-text ml-2 rounded-r-lg text-white p-2 hover:bg-pt-gray cursor-pointer transition-all duration-200 ease-in-out"
          @click="pageChange('+')"
        >
          <i class="fas fa-chevron-right"></i>
        </div>
      </div>

      <div
        class="p-4 bg-pt-gray-text my-8 rounded-lg"
        v-else
      >
        <img
          class="cursor-pointer w-500px"
          :src="bigImg"
          @click="setBigImg(null)"
        >
      </div>


    </div>

  </div>

</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      page: 1,
      bigImg: null
    }
  },
  components: {

  },
  methods: {
    setBigImg(src) {
      this.bigImg = src;
    },
    pageChange(type) {
      if(type == '-') {
        if(this.page == 1) {
          this.page = 5;
        } else {
          this.page -= 1;
        }
      } else {
        if(this.page == 4) {
          this.page = 1;
        } else {
          this.page += 1;
        }
      }
    }
  },
  computed: {
    // pagination() {

    // },
    paginationOld() {
      var pageStart = 1;
      var pageEnd = 10;

      if(this.page > 1) {
        pageStart = 10 * (this.page - 1);
        pageEnd = 10 * this.page;
      }
      if(this.page == 5) {
        pageEnd = 49;
      }

      console.log(pageStart,pageEnd);
      return {
        start: pageStart,
        end: pageEnd
      }
    }
  }
}
</script>